.table-container {
    max-width: 100%;
    overflow-x: auto;
}

.schedule-table {
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    font-weight: bold;
    background-color: #f2f2f2;
}

.schedule-table td, .schedule-table th {
    padding: 8px;
    text-align: left;
}

.schedule-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.schedule-table tr:hover {
    background-color: #ddd;
}

.action-btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
}

.action-btn-1{
    background-color: red; /* Green */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
}

.action-btn-disable{
    background-color:gray; /* Green */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
}

.action-btn:hover {
    background-color: #45a049;
}
