.header-img {
  margin-top: 10px;
  width: 390px;
  height: 57px;
  margin-bottom: 15px;
}

/* @media only screen and (max-width: 200px) {
  .Drawer {
    display: none;
  }
} */
