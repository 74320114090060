.date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.date-picker-title {
    padding: 10px;
    font-weight: bolder;
    color: #333;
    font-size: 18px;
}

.date-picker-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    gap: 10px;
    justify-content: start;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    scroll-behavior: smooth;
}

.date-picker-list::-webkit-scrollbar {
    height: 6px;
}

.date-picker-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.date-picker-item {
    padding: 9px 11px;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: background 0.3s ease, color 0.3s ease;
    background-color: #fff;
}

.date-picker-item:hover {
    background-color: #007BFF;
    color: white;
    border-color: #007BFF;
}

.date-picker-item.active {
    background-color: #28a745;
    color: white;
    border-color: #28a745;
}
