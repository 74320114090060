.profile-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
  margin-top: 50px;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.value {
  margin-bottom: 10px;
}
