/* CSS to remove the up/down arrows on number inputs */
.no-arrow input::-webkit-outer-spin-button,
.no-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrow input[type='number'] {
  -moz-appearance: textfield; /* For Firefox */
}
