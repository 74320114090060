.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-header {
  background-color: #e6e0e0;
}

.table-cell {
  font-weight: bold !important;
}

.no-data-found {
  text-align: center;
  font-weight: bold;
  color: red;
}