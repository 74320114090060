/* .form-container {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
   */

.addUser-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.addUser-form-container h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.addUser-form-group {
  margin-bottom: 15px;
}

.addUser-form-group .addUser-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #555;
}

.addUser-form-group .addUser-input,
.addUser-form-group .addUser-select {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addUser-form-group .addUser-input:focus,
.addUser-form-group .addUser-select:focus {
  border-color: #007BFF;
  outline: none;
}

.addUser-button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.addUser-button:hover {
  background-color: #0056b3;
}
