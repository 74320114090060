.add-edit-partner-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding: 20px;
  border-radius: 10px;
}

.add-edit-partner-form form{
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input[type='text'],
.form-group input[type='email'],
.form-group input[type='tel'],
.form-group select {
  height: 40px;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #007bff;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
}
.form-group:first-child {
  margin-right: 10px;
}

.schedule-table th,
.schedule-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.schedule-table th {
  text-align: left;
}

.schedule-table td {
  text-align: center;
}



.rockstar {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.label-container {
  margin-right: auto;
}

.radio-buttons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

.add-edit-button {
  display: block;
  margin: 0 auto;
  font-size: 1rem;
  padding: 10px 70px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}
