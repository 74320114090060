.slot-modal {
    display: block; 
    position: fixed; 
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}

.slot-modal-content {
    background-color: #fefefe;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px; 
}

.slot-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.slot-close:hover,
.slot-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.slot-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    color: wheat;
    border-radius: 10px;
    background-color:blue;
    font-weight: bold;
    cursor: pointer;
}

.slot-button:hover {
    background-color:gray;
}

.view-slot-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    color: wheat;
    border-radius: 10px;
    font-weight: bold;
    /* background-color: #f1f1f1; */
    background-color:green;
    cursor: pointer;
}