.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-header {
  background-color: #e6e0e0;
}

.table-cell {
  font-weight: bold !important;
}

.no-data-found {
  text-align: center;
  font-weight: bold;
  color: red;
}

.view-unchecked-img {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid black;
}

.allotted-incentive-div{
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 4px;
}

.allotted-edit-div{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.allotted-incentive-div input {
  width: 50px;
  border-radius: 8px;
  outline: none;
  -moz-appearance: textfield; /* Remove arrows in Firefox */
}

/* Remove arrows in Chrome, Safari, and Edge */
.allotted-incentive-div input::-webkit-outer-spin-button,
.allotted-incentive-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.allotted-edit-div button{
  padding: 4px 16px;
  border-radius: 8px;
  font-weight: 800;
}
.allotted-edit-div .edit-cancel{
  background-color: red;
color: white;
}
.allotted-edit-div .edit-button{
  background-color: dodgerblue;
color: white;
}
.allotted-edit-div .edit-submit{
  background-color: rgb(4, 190, 4);
  color: white;
}