.add-product-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.add-product-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
}

.add-product-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #888;
}

.add-product-close:hover {
    color: #000;
}

.add-product-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.add-product-select {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-product-submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.add-product-submit-button:hover {
    background-color: #0056b3;
}

.add-product-submit-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}