.product-step-form {
  max-width: 400px;
  margin: auto;
}

label {
  display: block;
  margin-bottom: 10px;
}

input,
textarea,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.add-step-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-step-button:hover {
  background-color: #45a049;
}
