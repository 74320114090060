h3 {
  margin: 20px;
  text-align: center;
}
.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.date-rage {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.date-rage input {
  padding: 10px;
  width: 180px;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.booking-search{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 10px;

}