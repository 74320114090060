.incentive-date-range,
.incentiv-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.incentive-date-range input {
  padding: 10px;
  width: 180px;
}
.incentive-heading {
  text-align: center;
}
.filter-bookings {
  display: flex;
  float: left;
  height: 51px;
}
.checked-incentive-amount{
text-align: right;
margin-bottom: 10px;
padding: 5px;
font-weight: bold;
margin-right: 25px;
}
