.custom-toast-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    background-color: rgb(186, 186, 189); 
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  }
  
  .toast-delete-btn {
    margin-top: 10px;
  }
  
  .yes-btn-confirm, .no-btn-confirm {
    margin: 0 10px;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease; 
  }
  
  .yes-btn-confirm {
    background-color: #28a745;
    color: white;
  }
  
  .no-btn-confirm {
    background-color: #dc3545;
    color: white;
  }
  
  .yes-btn-confirm:hover {
    background-color: #218838; 
  }
  
  .no-btn-confirm:hover {
    background-color: #c82333; 
  }
  
  .Toastify__toast-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }