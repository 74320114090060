.add-payment-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.add-payment-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.add-payment-label {
  text-align: left;
}

.add-payment-input,
.add-payment-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.add-payment-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.add-payment-button:hover {
  background-color: #0056b3;
}

.AllotTherapistContainer {
  width: 90vw;

  @media screen and (min-width: 768px) {
    width: unset;
  }
}

.filterContainer{
  width: 90vw;
  overflow-x: auto;

  @media screen and (min-width: 768px) {
    width: unset;
    overflow: hidden;
  }
}
